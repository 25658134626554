<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 pa-0 pa-sm-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          align="center"
          outlined
        >
        <v-layout wrap class="pb-4">
            <v-flex xs12 md5 lg8 text-md-left text-left>
            <span class="nsbold" style="font-size:18px">Student Outpass List</span><br/><span class="nsbold" style="font-size:15px">Session:{{$route.query.name}}</span>
            </v-flex>
            <v-flex xs12 md3 lg3>
            <v-text-field
              v-model="keyword"
              dense
              @keyup="getData()"
              class="rounded-xl"
              hide-details
              label="Search"
              outlined
              clearable
            ></v-text-field>
          </v-flex>
            <!-- <v-flex xs12 md3 lg1 pt-1 pl-1>
              <v-btn
              @click="getData()"
              style="font-size: 16px; font-family: Nunito Sans, SemiBold"
              color="#766BC0"
              dark
              class="rounded-xl"
              depressed
              
              >SEARCH</v-btn
            >
            </v-flex> -->
          <v-flex xs11 md3 lg1 text-md-right text-left align-self-center>
          <v-btn small plain>
           <v-icon @click="$router.go(-1)" title="Back"
                    style="cursor: pointer;color:#766BC0"
                    >mdi-arrow-left</v-icon
                  >
                  </v-btn></v-flex>
          
        </v-layout>
          <!----------------------filters---------------------------->
          <!-- <v-layout wrap>
            <v-flex xs12 sm3 md3 lg3>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    hide-details
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 md3 lg3 pl-sm-4 py-2 py-sm-0>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    outlined
                    hide-details
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            
             <v-flex xs12 sm3 md3 lg3 pl-sm-4>
            <v-text-field
              v-model="keyword"
              dense
              hide-details
              label="Search"
              outlined
              clearable
            ></v-text-field>
          </v-flex>
            <v-flex xs12 sm3 md3 lg3 pt-2 text-right>
                      <v-icon @click="$router.go(-1)" title="Back"
                    style="cursor: pointer;color:#766BC0"
                    >mdi-arrow-left</v-icon
                  >
                    </v-flex>
          </v-layout> -->
           
            <!-- <v-flex xs12 md6 lg3 pl-md-4 pt-md-6>
             <v-select
                    :items="issueStatus "
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select>
          </v-flex> -->
          
            <!--------------------------list------------------------>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="outpass">
              <v-layout wrap>
                <v-flex xs12 v-if="outpass.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                         <th class="text-left">No.</th>
                          <th class="text-left">Admission No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Outpass.No.</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Create Date</th>
                          <th class="text-left">In Date</th>
                          <th class="text-left">Out Date</th>
                          <th class="text-left">View</th>
                          <!-- <th class="text-left">View</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr>{{outpass}}</tr>  -->
                         <tr v-for="(item, i) in outpass" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ item.admissionNo }}
                          </td>
                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/profile?id=' + item.studentid._id"
                            >
                            {{ item.studentid.name }}
                            </router-link>
                            </td>
                          <td>
                            <span v-if="item.outPassNo">
                              {{ item.outPassNo }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.issueStatus">
                              {{ item.issueStatus }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.create_date">
                              {{ formatDate(item.create_date) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.inDate">
                              {{ formatDate(item.inDate) }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.outDate">
                              {{ formatDate(item.outDate) }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          
                          <td>
                            <v-dialog
                              v-model="item.dialog"
                              persistent
                              max-width="590"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  style="cursor: pointer; color: #766bc0"
                                  >mdi-eye</v-icon
                                >
                              </template>
                              <v-card>
                                <v-layout wrap>
                                  <v-flex xs12 pa-4>
                                    <v-layout wrap>
                                      <v-flex xs12 sm6 text-sm-left>
                                        <span
                                          class="nsbold"
                                          style="font-size: 16px"
                                        >
                                          Admission No: {{ item.admissionNo }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 sm6 text-sm-right>
                                        <span
                                          class="nsbold"
                                          style="font-size: 16px"
                                        >
                                          Outpass No: {{ item.outPassNo }}
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-layout wrap pa-md-4>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Name:</b> {{ item.studentid.name }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span v-if="item.outPassIssued"
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Isssued On:</b>
                                          {{ formatDate(item.create_date) }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>From:</b> {{ formatDate(item.fromDate) }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                         <b> Status:</b> {{ item.issueStatus }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>To:</b> {{ formatDate(item.toDate) }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Issued By:</b> {{ item.issuingAuthority }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Purpose:</b> {{ item.purpose }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right v-if="item.approvedBy">
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Approved By:</b> {{ item.approvedBy }}
                                        </span>
                                      </v-flex>
                                       <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Destination:</b> {{ item.destination }}
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <!-- <v-btn
                                    color="green darken-1"
                                    text
                                    @click="item.dialog = false"
                                  >
                                    Disagree
                                  </v-btn> -->
                                  <v-btn
                                    color="#766BC0"
                                    text
                                    @click="item.dialog = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </td>
                          <!-- <td>
                                          <span
                                            v-if="item.clothid.clothType.name"
                                          >
                                            {{ item.clothid.clothType.name }}
                                          </span>
                                          <span v-else>NA</span>
                                        </td>
                                        <td>
                                          {{ formatDate(item.update_date) }}
                                        </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
            <v-flex xs12 v-else>
              <span>No Data Available</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      menu: false,
      menu2: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      keyword: null,
      msg: null,
      //    issueStatus : ["All","Approved", "Out", "Active", "In"],
      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      outpass: null,
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.getData();
    // this.testData();

  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
    },
    // keyword() {
    //   if (this.page > 1) this.page = 1;
    //   // this.getData();
    //   this.getData();
    // },
    cvalue() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "post",
        url: "/student/outpassdata/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   issueStatus : a,
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
      })
        .then((response) => {

          this.outpass=[];
          this.appLoading = false;
          if (response.data.status == true) {
             if(response.data.data.length > 0)
              {
            this.outpass = response.data.data[0].outpasses;
              }
            // this.outpass = response.data.data[0].outpasses;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
